// /root/pwa-inapp/client/src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
//import Header from './components/Header';
import SidebarMenu from './components/SidebarMenu';
import Home from './pages/Home';
import GeneratePWA from './pages/GeneratePWA';
import PWAApp from './pages/PWAApp';
import Dashboard from './pages/User/Dashboard';
import PWAApplications from './pages/User/PWAApplications';
import MyDomains from './pages/User/MyDomains';
import Goals from './pages/User/Goals';
import Statistics from './pages/User/Statistics';
import TopUp from './pages/User/TopUp';
import UsersList from './pages/Admin/UsersList';
import Profile from './pages/User/Profile';
import Comments from './pages/User/Comments';
import PostbackSettings from './pages/PostbackSettings';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Tariffs from './pages/User/Tariffs';
import PromoCodes from './pages/Admin/PromoCodes';
import SystemDomains from './pages/Admin/SystemDomains';
import Feedback from './pages/User/Feedback';

const theme = createTheme();

function AppContent() {
  const { user, loading, checkApprovalStatus, isAdmin } = useAuth();
  const [isApproved, setIsApproved] = useState(null);
  const [checkingApproval, setCheckingApproval] = useState(true);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const checkStatus = async () => {
      if (user) {
        try {
          const status = await checkApprovalStatus();
          setIsApproved(status);
        } catch (error) {
          console.error('Error checking approval status:', error);
        }
      } else {
        setIsApproved(null);
      }
      setCheckingApproval(false);
    };

    if (!loading) {
      checkStatus();
    }
  }, [user, loading, checkApprovalStatus]);

  if (loading || checkingApproval) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const isPWARoute = location.pathname.startsWith('/pwa/');

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* {!isPWARoute && <Header toggleSidebar={toggleSidebar} />} */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {!isPWARoute && user && isApproved && (
          <SidebarMenu 
            isExpanded={isSidebarExpanded} 
            toggleSidebar={toggleSidebar} 
            isMobile={isMobile} 
          />
        )}
<Box
  component="main"
  sx={{
    flexGrow: 1,
    p: isPWARoute ? 0 : 3,
    mt: isPWARoute ? 0 : '64px',
    width: '100%',
    overflowY: 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }}
>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/generate-pwa" element={<GeneratePWA />} />
            <Route path="/pwa/:appId" element={<PWAApp />} />
            <Route
              path="/admin/promo-codes"
              element={user && isApproved && isAdmin ? <PromoCodes /> : <Navigate to="/" />}
            />
            <Route
              path="/dashboard"
              element={user && isApproved ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/pwa-applications"
              element={user && isApproved ? <PWAApplications /> : <Navigate to="/" />}
            />
            <Route
              path="/pwa/:pwaId/postback-settings"
              element={user && isApproved ? <PostbackSettings /> : <Navigate to="/" />}
            />
            <Route
  path="/postback-settings"
  element={user && isApproved ? <PostbackSettings /> : <Navigate to="/" />}
/>            
            <Route
              path="/comments"
              element={user && isApproved ? <Comments /> : <Navigate to="/" />}
            />
            <Route
              path="/my-domains"
              element={user && isApproved ? <MyDomains /> : <Navigate to="/" />}
            />
            <Route
              path="/goals"
              element={user && isApproved ? <Goals /> : <Navigate to="/" />}
            />
            <Route
              path="/statistics"
              element={user && isApproved ? <Statistics /> : <Navigate to="/" />}
            />
            <Route
              path="/top-up"
              element={user && isApproved ? <TopUp /> : <Navigate to="/" />}
            />
            <Route
              path="/admin/users"
              element={user && isApproved && isAdmin ? <UsersList /> : <Navigate to="/" />}
            />
            <Route
              path="/profile"
              element={user && isApproved ? <Profile /> : <Navigate to="/" />}
            />
            <Route path="/tariffs" element={<Tariffs />} />
            <Route
              path="/admin/system-domains"
              element={user && isApproved && isAdmin ? <SystemDomains /> : <Navigate to="/" />}
            />
            <Route
              path="/feedback"
              element={user && isApproved ? <Feedback /> : <Navigate to="/" />}
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
