import React from 'react';

function Feedback() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Обратная связь</h1>
      <p className="text-lg mb-4">
        Для связи с нами, пожалуйста, обратитесь к разработчику проекта:
      </p>
      <a 
        href="https://t.me/modeler111" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-600 hover:text-blue-800 text-lg font-medium"
      >
        https://t.me/modeler111
      </a>
    </div>
  );
}

export default Feedback;
