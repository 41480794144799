// /root/pwa-inapp/client/src/pages/User/TopUp.js

import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import PromoCodeInput from '../../components/PromoCodeInput';

function TopUp() {
  const { user, refreshUser } = useAuth();
  const [depositAddress, setDepositAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (user && user.depositAddress) {
      setDepositAddress(user.depositAddress);
    }
    fetchTransactions();
  }, [user]);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get('/api/transactions');
      setTransactions(response.data.filter(tx => tx.type !== 'withdrawal'));
    } catch (error) {
      console.error('Ошибка при загрузке транзакций:', error);
    }
  };

  const handleGenerateAddress = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/generate-deposit-address');
      setDepositAddress(response.data.depositAddress);
      await refreshUser();
    } catch (error) {
      console.error('Ошибка при генерации адреса:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTransactions = async () => {
    setUpdating(true);
    try {
      // Отправляем запрос на сервер для обновления транзакций
      await axios.post('/api/update-transactions');
      // После обновления получаем обновлённые данные
      await fetchTransactions();
      await refreshUser();
    } catch (error) {
      console.error('Ошибка при обновлении транзакций:', error);
    } finally {
      setUpdating(false);
    }
  };

  const handleBalanceUpdate = (newBalance) => {
    refreshUser();
  };

  const getTransactionDescription = (tx) => {
    if (tx.transactionId) {
      return (
        <a
          href={`https://tronscan.io/#/transaction/${tx.transactionId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {tx.transactionId.slice(0, 8)}...
        </a>
      );
    } else if (tx.type === 'promo') {
      return `Промокод: ${tx.promoCode || 'N/A'}`;
    } else if (tx.type === 'admin') {
      return 'Изменение администратором';
    } else {
      return tx.description || 'N/A';
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Пополнение баланса</h1>
      <PromoCodeInput onBalanceUpdate={handleBalanceUpdate} />
      <p className="text-lg mb-6 text-center text-gray-600">
        Отправьте USDT-TRC20 на адрес ниже для пополнения вашего баланса.
      </p>
      {depositAddress ? (
        <div className="text-center mb-8">
          <QRCodeCanvas value={depositAddress} size={200} className="mx-auto mb-4" />
          <p className="mt-4 font-mono text-xl bg-gray-100 p-3 rounded">{depositAddress}</p>
        </div>
      ) : (
        <div className="text-center mb-8">
          <p className="mb-4 text-gray-700">У вас еще нет адреса для пополнения.</p>
          <button
            onClick={handleGenerateAddress}
            className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Генерация адреса...' : 'Сгенерировать адрес для пополнения'}
          </button>
        </div>
      )}

      <div className="mt-12">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">История транзакций</h2>
          <button
            onClick={handleUpdateTransactions}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
            disabled={updating}
          >
            {updating ? 'Обновление...' : 'Обновить транзакции'}
          </button>
        </div>
        {transactions.length === 0 ? (
          <p className="text-center text-gray-600">Нет транзакций</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Описание</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Сумма</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Тип</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Статус</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Подтверждения</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Дата</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {transactions.map(tx => (
                  <tr key={tx._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {getTransactionDescription(tx)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {tx.amount > 0 ? `+${tx.amount}` : tx.amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.type}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        tx.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {tx.status === 'completed' ? 'Подтверждено' : 'Ожидание'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {tx.type === 'deposit' ? (
                        tx.confirmations >= 20 ? 'Достаточно' : tx.confirmations
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(tx.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopUp;
