// /root/pwa-inapp/client/src/components/PromoCodeInput.js
import React, { useState } from 'react';
import axios from 'axios';

function PromoCodeInput({ onBalanceUpdate }) {
  const [promoCode, setPromoCode] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('/api/promo-codes/use', { code: promoCode });
      setMessage(response.data.message);
      setPromoCode('');
      if (onBalanceUpdate) {
        onBalanceUpdate(response.data.newBalance);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Произошла ошибка при применении промокода');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-2 text-gray-700">Использовать промокод</h3>
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center">
        <input
          type="text"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder="Введите промокод"
          className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2 sm:mb-0 sm:mr-2 bg-white text-gray-900"
          style={{
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            outline: 'none',
          }}
        />
        <button 
          type="submit" 
          className={`px-4 py-2 rounded-md text-white font-medium ${
            isLoading 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          }`}
          disabled={isLoading}
        >
          {isLoading ? 'Применение...' : 'Применить'}
        </button>
      </form>
      {message && (
        <p className={`mt-2 text-sm ${message.includes('успешно') ? 'text-green-600' : 'text-red-600'}`}>
          {message}
        </p>
      )}
    </div>
  );
}

export default PromoCodeInput;