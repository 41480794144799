// /root/pwa-inapp/client/src/pages/Admin/SystemDomains.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  ArrowPathIcon, 
  CheckIcon, 
  LockClosedIcon, 
  TrashIcon
} from '@heroicons/react/24/outline';

const DomainStatus = {
  PENDING: 'Ожидание А-записи',
  A_RECORD_CONFIRMED: 'А-запись подтверждена',
  SSL_ISSUANCE: 'Выпуск HTTPS сертификата',
  READY: 'Готово',
  ERROR: 'Ошибка',
  SSL_ERROR: 'Ошибка выпуска SSL',
  SSL_EXPIRING: 'Сертификат истекает',
  SSL_EXPIRED: 'Сертификат истек'
};

function SystemDomains() {
  const [systemDomains, setSystemDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [checkingARecord, setCheckingARecord] = useState({});
  const [issuingSSL, setIssuingSSL] = useState({});

  useEffect(() => {
    fetchSystemDomains();
  }, []);

  const fetchSystemDomains = async () => {
    try {
      const response = await axios.get('/api/system-domains');
      setSystemDomains(response.data);
    } catch (error) {
      console.error('Ошибка при получении системных доменов:', error);
    }
  };

  const addSystemDomain = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/system-domains', { name: newDomain });
      setNewDomain('');
      fetchSystemDomains();
    } catch (error) {
      console.error('Ошибка при добавлении системного домена:', error);
    }
  };

  const deleteSystemDomain = async (id) => {
    try {
      await axios.delete(`/api/system-domains/${id}`);
      fetchSystemDomains();
    } catch (error) {
      console.error('Ошибка при удалении системного домена:', error);
    }
  };

  const checkARecord = async (domainId) => {
    setCheckingARecord(prev => ({ ...prev, [domainId]: true }));
    try {
      const response = await axios.post(`/api/system-domains/${domainId}/check-a-record`);
      const updatedDomains = systemDomains.map(domain => 
        domain._id === domainId ? { ...domain, status: response.data.status } : domain
      );
      setSystemDomains(updatedDomains);
    } catch (error) {
      console.error('Ошибка при проверке A-записи:', error);
    } finally {
      setCheckingARecord(prev => ({ ...prev, [domainId]: false }));
    }
  };

  const issueSSL = async (domainId) => {
    setIssuingSSL(prev => ({ ...prev, [domainId]: true }));
    try {
      const response = await axios.post(`/api/system-domains/${domainId}/issue-ssl`);
      const updatedDomains = systemDomains.map(domain => 
        domain._id === domainId ? { ...domain, status: response.data.status, sslExpirationDate: response.data.sslExpirationDate } : domain
      );
      setSystemDomains(updatedDomains);
    } catch (error) {
      console.error('Ошибка при выпуске SSL-сертификата:', error);
    } finally {
      setIssuingSSL(prev => ({ ...prev, [domainId]: false }));
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Управление системными доменами</h1>
      
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
        <p className="font-bold">Инструкция по настройке системного домена:</p>
        <p>1. Добавьте A-запись для вашего домена, указывающую на IP-адрес нашего сервера: 95.216.112.238</p>
        <p>2. Добавьте wildcard A-запись (*.ваш-домен.com) на тот же IP-адрес для поддержки поддоменов.</p>
        <p>3. После добавления домена, проверьте A-запись и выпустите SSL-сертификат.</p>
      </div>

      <form onSubmit={addSystemDomain} className="mb-6">
        <input
          type="text"
          value={newDomain}
          onChange={(e) => setNewDomain(e.target.value)}
          placeholder="Введите новый системный домен"
          className="border p-2 mr-2"
        />
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Добавить
        </button>
      </form>

      <ul>
        {systemDomains.map((domain) => (
          <li key={domain._id} className="border rounded-lg mb-4 p-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-semibold">{domain.name}</span>
              <div className="flex items-center space-x-2">
                <span className="mr-2">{domain.status}</span>
                <button
                  onClick={() => checkARecord(domain._id)}
                  disabled={checkingARecord[domain._id]}
                  className="bg-green-500 text-white px-2 py-1 rounded flex items-center disabled:bg-gray-300"
                >
                  {checkingARecord[domain._id] ? (
                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  ) : (
                    <CheckIcon className="h-5 w-5 mr-1" />
                  )}
                  {checkingARecord[domain._id] ? 'Проверка...' : 'Проверить А-запись'}
                </button>
                <button
                  onClick={() => issueSSL(domain._id)}
                  disabled={issuingSSL[domain._id]}
                  className="bg-yellow-500 text-white px-2 py-1 rounded flex items-center disabled:bg-gray-300"
                >
                  {issuingSSL[domain._id] ? (
                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  ) : (
                    <LockClosedIcon className="h-5 w-5 mr-1" />
                  )}
                  {issuingSSL[domain._id] ? 'Выпуск...' : 'Выпустить SSL'}
                </button>
                <button
                  onClick={() => deleteSystemDomain(domain._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded flex items-center"
                >
                  <TrashIcon className="h-5 w-5 mr-1" />
                  Удалить
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SystemDomains;