// /root/pwa-inapp/client/src/pages/User/Tariffs.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import moment from 'moment';

function Tariffs() {
  const [tariffs, setTariffs] = useState([]);
  const [userTariff, setUserTariff] = useState(null);
  const { user, refreshUser } = useAuth();

  useEffect(() => {
    fetchTariffs();
    fetchUserTariff();
  }, []);

  const fetchTariffs = async () => {
    try {
      const response = await axios.get('/api/tariffs');
      // Сортируем тарифы по цене для корректной логики
      const sortedTariffs = response.data.sort((a, b) => a.pricePerMonth - b.pricePerMonth);
      setTariffs(sortedTariffs);
    } catch (error) {
      console.error('Ошибка при получении тарифов:', error);
    }
  };

  const fetchUserTariff = async () => {
    try {
      const response = await axios.get('/api/tariffs/user/tariff');
      setUserTariff(response.data);
    } catch (error) {
      console.error('Ошибка при получении тарифа пользователя:', error);
      setUserTariff(null);
    }
  };

  const purchaseTariff = async (tariffId) => {
    try {
      const response = await axios.post('/api/tariffs/purchase', { tariffId });
      await refreshUser();
      await fetchUserTariff();
      alert(response.data.message);
    } catch (error) {
      console.error('Ошибка при покупке тарифа:', error);
      alert(error.response?.data?.error || 'Ошибка при покупке тарифа');
    }
  };

  return (
    <div className="container mx-auto px-4 mt-8">
      <h1 className="text-3xl font-bold text-center mb-8">Выберите тариф</h1>

      {userTariff && userTariff.name ? (
        <div className="mb-8 text-center">
          <h2 className="text-2xl">Ваш текущий тариф: {userTariff.name}</h2>
          <p>
            Действителен до: {moment(userTariff.expiration).format('DD.MM.YYYY HH:mm')}
          </p>
          {userTariff.pendingTariff && (
            <p className="mt-2 text-yellow-600">
              Запланирован переход на тариф: {userTariff.pendingTariff.name}
            </p>
          )}
        </div>
      ) : (
        <p className="mb-8 text-center text-red-500">У вас нет активного тарифа.</p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {tariffs.map((tariff, index) => {
          const isCurrentTariff = userTariff && userTariff.name === tariff.name;
          const isPendingTariff =
            userTariff && userTariff.pendingTariff && userTariff.pendingTariff.name === tariff.name;

          const isMinTariff = index === 0;
          const isMaxTariff = index === tariffs.length - 1;

          let disableButton = false;
          let buttonText = 'Выбрать';

          if (isCurrentTariff) {
            disableButton = true;
            buttonText = 'Текущий тариф';
          } else if (isPendingTariff) {
            disableButton = true;
            buttonText = 'Запланирован';
          } else if (userTariff && userTariff.name) {
            const currentTariffIndex = tariffs.findIndex((t) => t.name === userTariff.name);

            if (currentTariffIndex === -1) {
              disableButton = true;
            } else {
              if (currentTariffIndex === 0 && index < currentTariffIndex) {
                // Нельзя понизить тариф ниже минимального
                disableButton = true;
              }
              if (currentTariffIndex === tariffs.length - 1 && index > currentTariffIndex) {
                // Нельзя повысить тариф выше максимального
                disableButton = true;
              }
            }
          }

          const handlePurchaseClick = () => {
            if (userTariff && userTariff.name) {
              const currentTariffIndex = tariffs.findIndex((t) => t.name === userTariff.name);
              const newTariffIndex = index;

              if (newTariffIndex < currentTariffIndex) {
                // Понижение тарифа
                if (
                  window.confirm(
                    'Переход на более низкий тариф вступит в силу после окончания текущего тарифа. Вы хотите продолжить?'
                  )
                ) {
                  purchaseTariff(tariff._id);
                }
              } else if (newTariffIndex > currentTariffIndex) {
                // Повышение тарифа
                if (
                  window.confirm(
                    'Переход на более высокий тариф произойдёт немедленно с вычитанием одного дня из текущего тарифа. Вы хотите продолжить?'
                  )
                ) {
                  purchaseTariff(tariff._id);
                }
              } else {
                alert('Вы уже на этом тарифе.');
              }
            } else {
              // У пользователя нет текущего тарифа
              purchaseTariff(tariff._id);
            }
          };

          return (
            <div key={tariff._id} className="border p-6 rounded-lg shadow-lg flex flex-col items-center">
              <h2 className="text-2xl font-bold mb-4">{tariff.name}</h2>
              <p className="text-4xl font-extrabold mb-4">
                ${tariff.pricePerMonth}
                <span className="text-lg font-normal">/месяц</span>
              </p>
              <ul className="text-center mb-6">
                <li className="mb-2">Количество PWA: {tariff.pwaLimit}</li>
                <li className="mb-2">
                  Пуш-уведомления: до {tariff.pushLimitPerPWA.toLocaleString()} в месяц на одно PWA
                </li>
              </ul>
              <button
                onClick={handlePurchaseClick}
                disabled={disableButton}
                className={`mt-auto px-6 py-2 ${
                  disableButton ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } text-white rounded`}
              >
                {buttonText}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Tariffs;
