// /root/pwa-inapp/client/src/pages/Admin/PromoCodes.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function PromoCodes() {
  const [promoCodes, setPromoCodes] = useState([]);
  const [newPromoCode, setNewPromoCode] = useState({ code: '', amount: '', totalUses: '' });

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const fetchPromoCodes = async () => {
    try {
      const response = await axios.get('/api/promo-codes');
      setPromoCodes(response.data);
    } catch (error) {
      console.error('Ошибка при получении промокодов:', error);
    }
  };

  const handleInputChange = (e) => {
    setNewPromoCode({ ...newPromoCode, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/promo-codes', newPromoCode);
      setNewPromoCode({ code: '', amount: '', totalUses: '' });
      fetchPromoCodes();
    } catch (error) {
      console.error('Ошибка при создании промокода:', error);
    }
  };

  const handleDelete = async (promoId) => {
    if (window.confirm('Вы уверены, что хотите удалить этот промокод?')) {
      try {
        await axios.delete(`/api/promo-codes/${promoId}`);
        fetchPromoCodes();
      } catch (error) {
        console.error('Ошибка при удалении промокода:', error);
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Управление промокодами</h1>
      
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="flex flex-wrap -mx-2 mb-4">
          <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <input
              type="text"
              name="code"
              value={newPromoCode.code}
              onChange={handleInputChange}
              placeholder="Код"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <input
              type="number"
              name="amount"
              value={newPromoCode.amount}
              onChange={handleInputChange}
              placeholder="Сумма"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="w-full md:w-1/3 px-2">
            <input
              type="number"
              name="totalUses"
              value={newPromoCode.totalUses}
              onChange={handleInputChange}
              placeholder="Количество использований"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Создать промокод
        </button>
      </form>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Код</th>
            <th className="border p-2">Сумма</th>
            <th className="border p-2">Использовано</th>
            <th className="border p-2">Всего использований</th>
            <th className="border p-2">Активен</th>
            <th className="border p-2">Действия</th>
          </tr>
        </thead>
        <tbody>
          {promoCodes.map((promo) => (
            <tr key={promo._id}>
              <td className="border p-2">{promo.code}</td>
              <td className="border p-2">{promo.amount}</td>
              <td className="border p-2">{promo.usedCount}</td>
              <td className="border p-2">{promo.totalUses}</td>
              <td className="border p-2">{promo.isActive ? 'Да' : 'Нет'}</td>
              <td className="border p-2">
                <button
                  onClick={() => handleDelete(promo._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PromoCodes;