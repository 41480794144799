// /root/pwa-inapp/client/src/pages/Admin/UsersList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UsersList() {
  const [users, setUsers] = useState([]);
  const [balanceUpdates, setBalanceUpdates] = useState({});
  const [withdrawalAmounts, setWithdrawalAmounts] = useState({});
  const [loadingWithdrawals, setLoadingWithdrawals] = useState({});
  const [energyEstimates, setEnergyEstimates] = useState({});
  const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
  const [estimatedEnergyForWithdrawal, setEstimatedEnergyForWithdrawal] = useState({});

  useEffect(() => {
    fetchUsers();
    fetchWithdrawalTransactions();
  }, []);

  const fetchUsers = async () => {
    try {
      console.log('Запрос на получение списка пользователей');
      const response = await axios.get('/api/admin/users');
      console.log('Полученные пользователи:', response.data);
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    }
  };

  const fetchWithdrawalTransactions = async () => {
    try {
      console.log('Запрос на получение транзакций вывода');
      const response = await axios.get('/api/withdrawal-transactions');
      console.log('Полученные транзакции вывода:', response.data);
      setWithdrawalTransactions(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке транзакций вывода:', error);
    }
  };

  const updateBalances = async () => {
    console.log('Начало обновления балансов');
    try {
      const response = await axios.post('/api/update-balances');
      console.log('Ответ от сервера после обновления балансов:', response.data);
      await fetchUsers();
      console.log('Пользователи после обновления:', users);
    } catch (error) {
      console.error('Ошибка при обновлении балансов:', error);
    }
  };

  const toggleApproval = async (userId, currentStatus) => {
    try {
      console.log(`Изменение статуса одобрения для пользователя ${userId} на ${!currentStatus}`);
      await axios.post(`/api/admin/toggle-approval/${userId}`, { isApproved: !currentStatus });
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при изменении статуса пользователя:', error);
    }
  };

  const handleBalanceChange = (userId, value) => {
    setBalanceUpdates({ ...balanceUpdates, [userId]: value });
  };

  const updateBalance = async (userId, operation) => {
    try {
      const amount = parseFloat(balanceUpdates[userId]);
      if (isNaN(amount) || amount <= 0) {
        alert('Пожалуйста, введите положительную сумму.');
        return;
      }

      console.log(`Обновление баланса для пользователя ${userId}: операция=${operation}, сумма=${amount}`);

      await axios.post(`/api/admin/update-balance/${userId}`, { amount, operation });
      setBalanceUpdates({ ...balanceUpdates, [userId]: '' });
      fetchUsers();
      alert(`Баланс пользователя успешно ${operation === 'add' ? 'добавлен' : 'вычтен'}.`);
    } catch (error) {
      const serverError = error.response?.data?.error || 'Неизвестная ошибка';
      console.error('Ошибка при обновлении баланса пользователя:', serverError);
      alert(`Ошибка: ${serverError}`);
    }
  };

  const handleWithdrawalAmountChange = (userId, value) => {
    setWithdrawalAmounts({ ...withdrawalAmounts, [userId]: value });
    setEnergyEstimates((prev) => ({ ...prev, [userId]: null }));
    setEstimatedEnergyForWithdrawal((prev) => ({ ...prev, [userId]: null }));
  };

  const checkEnergy = async (userId) => {
    try {
      const amount = parseFloat(withdrawalAmounts[userId]);
      if (isNaN(amount) || amount <= 0) {
        alert('Пожалуйста, введите корректную сумму для вывода.');
        return;
      }

      console.log(`Запрос на оценку энергии для пользователя ${userId} с суммой ${amount} USDT`);

      const response = await axios.post('/api/estimate-energy', { userId, amount });
      if (response.data && response.data.estimatedEnergy) {
        console.log(`Получена оценка энергии для пользователя ${userId}: ${response.data.estimatedEnergy} TRX`);
        setEnergyEstimates((prev) => ({ ...prev, [userId]: response.data.estimatedEnergy }));
        setEstimatedEnergyForWithdrawal((prev) => ({ ...prev, [userId]: response.data.recommendedFeeLimit }));
      } else {
        throw new Error('Некорректный ответ от сервера');
      }
    } catch (error) {
      console.error('Ошибка при оценке энергии:', error);
      let errorMessage = 'Неизвестная ошибка';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      alert(`Ошибка при оценке энергии: ${errorMessage}`);
      setEnergyEstimates((prev) => ({ ...prev, [userId]: null }));
      setEstimatedEnergyForWithdrawal((prev) => ({ ...prev, [userId]: null }));
    }
  };

  const initiateWithdrawal = async (userId) => {
    try {
      const amount = parseFloat(withdrawalAmounts[userId]);
      if (isNaN(amount) || amount <= 0) {
        alert('Пожалуйста, введите положительную сумму для вывода.');
        return;
      }

      console.log(`Инициирование вывода средств для пользователя ${userId} на сумму ${amount} USDT`);

      setLoadingWithdrawals((prev) => ({ ...prev, [userId]: true }));

      let estimatedEnergy = estimatedEnergyForWithdrawal[userId];
      if (!estimatedEnergy) {
        console.log('Оценка энергии не была выполнена ранее, выполняем сейчас');
        const energyResponse = await axios.post('/api/estimate-energy', { userId, amount });
        estimatedEnergy = energyResponse.data.recommendedFeeLimit;
        setEstimatedEnergyForWithdrawal((prev) => ({ ...prev, [userId]: estimatedEnergy }));
        setEnergyEstimates((prev) => ({ ...prev, [userId]: energyResponse.data.estimatedEnergy }));
      }

      // Запрашиваем подтверждение у пользователя
      const confirmWithdrawal = window.confirm(`Оценочная стоимость энергии: ${estimatedEnergy / 1e6} TRX. Продолжить вывод?`);

      if (!confirmWithdrawal) {
        console.log('Пользователь отменил вывод средств');
        setLoadingWithdrawals((prev) => ({ ...prev, [userId]: false }));
        return;
      }

      console.log(`Отправка запроса на вывод средств для пользователя ${userId}`);

      const response = await axios.post('/api/withdraw', {
        userId,
        amount,
        estimatedEnergy
      });

      console.log('Вывод средств успешно инициирован:', response.data);

      setWithdrawalAmounts({ ...withdrawalAmounts, [userId]: '' });
      setEnergyEstimates((prev) => ({ ...prev, [userId]: null }));
      setEstimatedEnergyForWithdrawal((prev) => ({ ...prev, [userId]: null }));
      fetchUsers();
      fetchWithdrawalTransactions();
      alert('Вывод средств успешно инициирован.');
    } catch (error) {
      const serverError = error.response?.data?.error || 'Неизвестная ошибка';
      console.error('Ошибка при выводе средств:', serverError);
      alert(`Ошибка: ${serverError}`);
    } finally {
      setLoadingWithdrawals((prev) => ({ ...prev, [userId]: false }));
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Управление пользователями</h1>
        <button
          onClick={updateBalances}
          className="mt-4 md:mt-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Обновить фактические балансы
        </button>
      </div>
      <div className="overflow-x-auto bg-white rounded-lg shadow">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full table-fixed divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* Заголовки таблицы */}
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Имя пользователя</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Статус</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Кол-во PWA</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Баланс аккаунта</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Баланс USDT-TRC20</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Баланс TRX</th>
                  <th scope="col" className="w-1/5 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Вывод средств</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user._id}>
                    <td className="px-4 py-2 text-sm font-medium text-gray-900 truncate">{user.username}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 truncate">{user.email}</td>
                    <td className="px-4 py-2 text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.isApproved ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {user.isApproved ? 'Одобрен' : 'Не одобрен'}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">{user.pwaCount || 0}</td>
                    <td className="px-4 py-2 text-sm font-medium">
                      <button
                        onClick={() => toggleApproval(user._id, user.isApproved)}
                        className={`text-xs px-3 py-1 rounded-md transition-colors w-full md:w-auto ${
                          user.isApproved
                            ? 'bg-red-100 text-red-700 hover:bg-red-200'
                            : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                        }`}
                      >
                        {user.isApproved ? 'Отменить' : 'Одобрить'}
                      </button>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="flex flex-col">
                        <span>${typeof user.balance === 'number' ? user.balance.toFixed(2) : '0.00'}</span>
                        <div className="mt-2 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                          <input
                            type="number"
                            placeholder="Сумма"
                            value={balanceUpdates[user._id] || ''}
                            onChange={(e) => handleBalanceChange(user._id, e.target.value)}
                            className="w-full sm:w-24 px-2 py-1 text-xs border border-gray-300 rounded mb-2 sm:mb-0"
                          />
                          <button
                            onClick={() => updateBalance(user._id, 'add')}
                            className="w-full sm:w-auto text-xs px-2 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200 transition-colors"
                          >
                            Добавить
                          </button>
                          <button
                            onClick={() => updateBalance(user._id, 'subtract')}
                            className="w-full sm:w-auto text-xs px-2 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
                          >
                            Вычесть
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      {user.depositAddress
                        ? (typeof user.usdtBalance === 'number' ? `$${user.usdtBalance.toFixed(2)}` : 'Нет данных')
                        : 'Нет кошелька'}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      {user.depositAddress
                        ? (typeof user.trxBalance === 'number' ? `${user.trxBalance.toFixed(6)} TRX` : 'Нет данных')
                        : 'Нет кошелька'}
                    </td>
                    <td className="px-4 py-2 text-sm">
                      <div className="flex flex-col space-y-2">
                        <input
                          type="number"
                          placeholder="Сумма"
                          value={withdrawalAmounts[user._id] || ''}
                          onChange={(e) => handleWithdrawalAmountChange(user._id, e.target.value)}
                          className="w-full px-2 py-1 text-xs border border-gray-300 rounded"
                        />
                        <div className="flex space-x-2">
                          <button
                            onClick={() => initiateWithdrawal(user._id)}
                            className="w-1/2 text-xs px-2 py-1 bg-purple-100 text-purple-700 rounded hover:bg-purple-200 transition-colors"
                            disabled={loadingWithdrawals[user._id]}
                          >
                            {loadingWithdrawals[user._id] ? 'Выполняется...' : 'Вывести USDT'}
                          </button>
                          <button
                            onClick={() => checkEnergy(user._id)}
                            className="w-1/2 text-xs px-2 py-1 bg-blue-100 text-blue-700 rounded hover:bg-blue-200 transition-colors"
                          >
                            Проверить энергию
                          </button>
                        </div>
                        {energyEstimates[user._id] !== null && (
                          <div className="text-xs text-gray-600">
                            Оценка энергии: {energyEstimates[user._id]} TRX
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Таблица транзакций вывода */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Транзакции вывода</h2>
        {withdrawalTransactions.length === 0 ? (
          <p className="text-center text-gray-600">Нет транзакций вывода</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID транзакции</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Сумма (USDT)</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Статус</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Дата</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {withdrawalTransactions.map(tx => (
                  <tr key={tx._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <a
                        href={`https://tronscan.io/#/transaction/${tx.transactionId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {tx.transactionId.slice(0, 8)}...
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.amount}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        tx.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {tx.status === 'completed' ? 'Завершено' : 'В обработке'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(tx.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersList;
